import {    
    Toolbar,
    Typography,   
    IconButton,
    Button,
    Drawer,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import NavListDrawerAdm from "../NavListDrawerAdm";
import { Box } from '@mui/system'
import axios from 'axios';
import logo from '../../assets/logo_2x-removebg-preview.png'
import HomeIcon from '@mui/icons-material/Home';

import CellTowerIcon from '@mui/icons-material/CellTower';

import { useAuth } from '../../context/AuthContext';



const navLink = [
    {
        title:'Proyectos', path: '/proyectos', icon: <HomeIcon/>
    },
    {
        title:'Clientes', path: '/clientes', icon: <CellTowerIcon/>
    },
    
]

export default function NavBarAdm() {
    const [error, setError] = useState ('');
    const[open, setOpen] = useState(false)
    const [isAuth, setIsAuth] = useState(false)
    const { logout } = useAuth(); //esta funcion viene de /context/AuthContext

    const handleLogout = async () => {
        try{
            await logout();
        } catch (error){ 
            setError('Server Error')
        }
    }

    /*const axiosClient = axios.create({
        baseURL:'http://localhost:4000'
    })*/

    const axiosClient = axios.create({
        baseURL:'https://desamd.cl:4000'
    })

    const isAuthenticated = async()=>{
        await axiosClient.get('api/proyectos/checkAuth')
        .then(response => {
            setIsAuth(response.data.authenticated)
        })
    }

    useEffect(() => {
        isAuthenticated();
    }, []);

    const handleButtonClick = () => {
        // Reemplaza con la URL que deseas abrir
        const url = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=166622833855-dheb0ef19kgma7b0ffq9ajfl994iqqvp.apps.googleusercontent.com&redirect_uri=https://desamd.cl:4000/api/redirect&response_type=code&scope=email%20profile%20https://www.googleapis.com/auth/drive.file&access_type=offline&include_granted_scopes=true&prompt=consent';
        window.open(url, '_blank');
    };

    return (
        <>
            
                <Toolbar >
                    <IconButton
                        onClick={() => setOpen(true)}
                        size = 'large'
                        sx = {{display: {xs:"block", sm:"none"}}}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src= { logo } />
                    <Typography sx = {{ flexGrow:1 }}></Typography>

                    <Box sx = {{display: {xs:"none", sm:"block"}, marginTop:4}}>
                        {
                            navLink.map(item =>(
                                <Button
                                    color = 'inherit'
                                    key={item.title}
                                    component = 'a'
                                    href = {item.path}
                                    sx={{ fontFamily: 'Open Sans, sans-serif' }}
                                >
                                    {item.title}
                                </Button>
                            ))
                        }
                        
                        <Button
                            variant = 'contained'
                            
                            onClick = {handleLogout}
                            sx={{marginLeft: 2, marginRight: 2}}
                        >
                            Salir
                        </Button>

                        <Button 
                            variant='contained'
                            color="error"
                            disabled={isAuth}
                            onClick={handleButtonClick}
                        >
                            Autenticarse 
                        </Button>
                    </Box>

                </Toolbar>
            

            <Drawer 
            open = {open}
            anchor="left"
            onClose={() => setOpen(false)}
            >
                <NavListDrawerAdm navLink = {navLink}/>
            </Drawer>
           
        </>
    );
}       